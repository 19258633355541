import { useLocalStorage } from '@vueuse/core'

export default () => {
  const email = useLocalStorage('email', '')
  const phone = useLocalStorage('phone', '')
  const district = useLocalStorage<{ name: string, permissions?: unknown, id?: string, stine?: boolean }>('district', { name: '' })

  return {
    email,
    phone,
    district
  }
}
